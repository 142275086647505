










































import Vue from "vue";
import db from "@/firebase/db";
import fb from "firebase/app";
import DBHelper from "@/tscript/dbHelper";
import * as _ from "lodash";
import MycDialog from "@/components/global/dialog/MycDialog.vue";
import { mapActions, mapState } from "vuex";
import { computeStandardAdminDataSchema } from "@/tscript/adminHelper";
import { DOMAIN_URL } from "@/config/index";
import { ZONE } from "@/config/index";

export default Vue.extend({
  components: { MycDialog },
  props: {
    optionModule: {
      type: String,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editObject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
      patientId: "",
      titleDialog: this.$i18n.t(`global.new_admission`),
      realoadAutocomplete: true,
      isRegisteredAsBenificiary: false,
      searchInput: "",
      patientModel: {} as any,
      patientsList: new Array(),
      step: 1,
      schema: {},
    };
  },
  methods: {
    ...mapActions("snackbar", ["setSnackbarAction"]),
    getAge(birthday: any) {
      let dateOfBirth: any = new Date(birthday);
      const diff: any = Date.now() - dateOfBirth.getTime();
      const age = new Date(diff);
      return Math.abs(age.getUTCFullYear() - 1970);
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    getWidth() {
      return 670;
    },
    updateDataFromPatient(patientSelected: any) {
      this.initPatientAdminData();
      this.searchInput = "";
      if (!patientSelected) {
        this.step = 1;
        this.isRegisteredAsBenificiary = false;
      } else {
        this.isRegisteredAsBenificiary = true;
        this.patientModel = { ...this.patientModel, ...patientSelected };
        this.step = 2;
      }
    },

    async savePatientAdminData() {
      const patientToSave: any = this.patientModel;
      patientToSave.done_by = this.userData?.id ?? "";
      patientToSave.last_update = fb.firestore.Timestamp.now();

      if (this.isRegisteredAsBenificiary) {
        patientToSave.created_at = fb.firestore.Timestamp.now();
        this.patientId = patientToSave.id;
      }

      let resultSave: any = null;
      const getParam = this.isRegisteredAsBenificiary ? "edit" : "new";
      delete patientToSave.id;
      if (getParam == "new") {
        //patientToSave.link = DOMAIN_URL;
        resultSave = await this.dbHelper.addDataToCollection(
          "patients_admin",
          patientToSave
        );
      } else {
        resultSave = await this.dbHelper.setDataToCollection(
          "patients_data",
          this.patientId,
          patientToSave
        );
      }
      patientToSave.id = this.patientId;
      if (resultSave) {
        this.setSnackbarAction({
          status: true,
          message: this.$i18n.t("snackbar.data_saved"),
          type: "success",
        });
        this.closeDialog();
        this.$store.dispatch("patientsListAction", null);
      } else {
        this.setSnackbarAction({
          status: true,
          message: this.$i18n.t("snackbar.error"),
          type: "error",
        });
      }
    },
    initPatientAdminData() {
      this.patientModel = {
        first_name: "",
        last_name: "",
        birth_date: null,
        sex: "",
        country: "",
        email: "",
        prefix: "1",
        phone: "",
        last_update: null,
      };
    },
    failedValidation() {
      setTimeout(function () {
        const el = document.querySelector(".formulate-input-error");
        el?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 500);
    },
  },
  created() {
    this.initPatientAdminData();
    this.isRegisteredAsBenificiary = false;
    this.realoadAutocomplete = !this.realoadAutocomplete;
    this.patientsList = this.$store.state.patientsList;
    this.schema = computeStandardAdminDataSchema(
      "",
      "",
      true,
      this.countriesList
    );
    //this.patientModel = this.userSitesList[0];
    switch (this.optionModule) {
      case "Outpatient": {
        this.titleDialog = this.$i18n.t(`global.new_patient`);
        this.step = 2;
        break;
      }
    }
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("countries", ["countriesList"]),
  },
});
