import i18n from "@/i18n";
import * as _ from "lodash";
import slugify from "slugify";
import { cstSimple, cstRecurring } from "@/config/constants";
import { getCountriesList } from "@/components/admin/adminHelper";

function getCampaign(campaignsList: any) {
  const results = new Array();

  if (campaignsList) {
    for (const c of campaignsList) {
      const slot = {
        value: c.id,
        label: c.name,
      };
      results.push(slot);
    }
  }
  return results;
}

function getContraindications(
  showContraindications: boolean,
  campaignsList: any,
  values: any
) {
  let contraindications = new Array();
  let displayContraindications = false;

  if (showContraindications) {
    displayContraindications = true;
  } else {
    if (values?.campaign_id) {
      const campaign: any = _.find(campaignsList, {
        id: values?.campaign_id,
      });

      if (campaign?.type === "immunization") {
        displayContraindications = true;
      }
    }
  }

  if (displayContraindications) {
    contraindications = [
      {
        component: "div",
        class: "d-flex justify-left",
        name: "row14",
        children: [
          {
            component: "a",
            class: "form-link-important",
            target: "_BLANK",
            name: "row8a2",
            href: "/doc/fiche_information.pdf",
            children: i18n.t("global.please_read_informations"),
          },
        ],
      },
      {
        component: "div",
        class: "d-flex justify-left",
        name: "row15",
        children: [
          {
            component: "a",
            class: "form-link-important",
            target: "_BLANK",
            name: "row8a2",
            href: "/doc/questionnaire.pdf",
            children: i18n.t("global.get_questionnary"),
          },
        ],
      },
    ];
  }
  return contraindications;
}

export const computeStandardAdminDataSchema = (
  validationPhone = "required",
  validationEmail = "email",
  isNewAdmission = false,
  countriesList = new Array()
) => {
  let countriesFormatted = countriesList.map((country: any) => {
    const obj: any = {};
    const dialCode = parseInt(country.callingCodes[0]?.substring(1), 10);
    obj.value = dialCode;
    obj.label = country.flag + " " + dialCode;
    obj.id = country.flag + country.dial_code + country.name;
    return obj;
  });
  countriesFormatted = _.sortBy(countriesFormatted, (o) => o.value);

  const countries: any = {};
  for (const c of getCountriesList(countriesList, true)) {
    countries[c.value] = c.text;
  }

  const standardAdminDataSchema = [
    {
      component: "h4",
      class: "form-title",
      name: "new-section",
      children: i18n.t("patient.personal_data"),
    },
    {
      component: "div",
      class: "container-row-input",
      name: "row1",
      children: [
        {
          class: "input-text required",
          placeholder: i18n.t("global.last_name"),
          type: "text",
          name: "last_name",
          label: i18n.t("global.last_name"),
          validation: "required",
          validationName: i18n.t("global.last_name"),
          errorBehavior: "submit",
        },
        {
          class: "input-text required",
          placeholder: i18n.t("global.first_name"),
          type: "text",
          name: "first_name",
          label: i18n.t("global.first_name"),
          validation: "required",
          validationName: i18n.t("global.first_name"),
          errorBehavior: "submit",
        },
      ],
    },
    {
      component: "div",
      class: "container-row-input",
      name: "row9",
      children: [
        {
          class: "input-datePicker required",
          name: "birth_date",
          placeholder: "01/01/1990",
          label: i18n.t("global.birthday_date"),
          type: "datePicker",
          standardFormat: false,
          validation: "required",
          validationName: i18n.t("global.birthday_date"),
        },
        {
          class: "input-select",
          name: "sex",
          id: "sex",
          label: i18n.t("global.sex"),
          type: "select",
          validation: "required",
          options: {
            M: i18n.t("global.male"),
            F: i18n.t("global.feminine"),
          },
          validationName: i18n.t("global.sex"),
        },
      ],
    },
    {
      component: "div",
      class: "container-row-input",
      name: "row-countries",
      children: [
        {
          class: "input-select required",
          name: "country",
          id: "country",
          label: i18n.t("global.country"),
          type: "select",
          options: countries,
          validation: "required",
          validationName: i18n.t("global.country"),
        },
        {
          class: "input-text",
          type: "text",
          name: "city",
          label: i18n.t("global.city"),
          validationName: i18n.t("global.city"),
        },
      ],
    },
    {
      component: "h4",
      class: "form-title",
      name: "new-section-contact",
      children: i18n.t("global.contact"),
    },
    {
      component: "div",
      class: "container-row-input",
      name: "row2b",
      children: [
        {
          component: "div",
          class: "input-phone",
          children: [
            {
              class: "input-phone-prefix",
              type: "select",
              name: "prefix",
              label:
                validationPhone == "required"
                  ? i18n.t("global.prefix")
                  : i18n.t("global.prefix"),
              validation: validationPhone,
              validationName: i18n.t("global.prefix"),
              errorBehavior: "submit",
              options: countriesFormatted,
            },
            {
              class: "input-phone-number",
              type: "tel",
              name: "phone",
              placeholder: "06 08 09 10 11",
              label:
                validationPhone == "required"
                  ? i18n.t("global.phone_number")
                  : i18n.t("global.phone_number"),
              validation: validationPhone,
              validationName: i18n.t("global.phone_number"),
              errorBehavior: "submit",
            },
          ],
        },
        {
          // Partie contact
          class: "input-email",
          type: "email",
          name: "email",
          placeholder: i18n.t("global.exemple_email"),
          label: i18n.t("global.email_address"),
          validation: validationEmail,
          validationName: i18n.t("global.email_address"),
          errorBehavior: "submit",
        },
      ],
    },
  ];

  const field: any = {
    component: "div",
    class: "container-row-input",
    name: "row14",
    children: [],
  };

  if (isNewAdmission) {
    /* const emailField: any = (standardAdminDataSchema[2].children as any).splice(-1);
    const phoneField: any = standardAdminDataSchema[4];

    const genderField: any = {
      class: "input-select required",
      name: "sex",
      id: "sex",
      label: i18n.t("global.sex") + "*",
      type: "select",
      validation: "required",
      options: {
        M: i18n.t("global.male"),
        F: i18n.t("global.feminine"),
      },
      validationName: i18n.t("global.sex"),
    };

    (standardAdminDataSchema[2].children as any).push(genderField);

    const countryField: any = {
      component: "div",
      class: "container-row-input",
      name: "country-field",
      children: [
        {
          class: "input-select-autocomplete required",
          type: "autoComplete",
          id: "country_of_origin",
          name: "country_of_origin",
          items: getCountryList(),
          validation: "required",
          label: i18n.t("global.nationality"),
          validationName: i18n.t("global.nationality"),
        },
      ],
    };
    standardAdminDataSchema.splice(3, 0, countryField);

    const contactField: any = {
      component: "h4",
      class: "form-title",
      name: "contact-section",
      children: i18n.t("outpatient.contact_data"),
    };

    standardAdminDataSchema.push(contactField);
    phoneField.children.push(emailField[0]);

    standardAdminDataSchema.push(phoneField); */

    const sumbitAddmission: any = {
      component: "div",
      class: "container-row-submit",
      name: "row-submit",
      children: [
        {
          class: "input-submit",
          type: "submit",
          label: i18n.t("global.save"),
        },
      ],
    };
    standardAdminDataSchema.push(sumbitAddmission);
  }

  return standardAdminDataSchema;
};

const computeAdditionalDataSchema = (additionalForms: any) => {
  const additionalDataSchema = [];
  for (const form of additionalForms) {
    if (form?.fields) {
      for (const elem of form.fields) {
        const name = slugify(elem.field_name).toLowerCase().replace(/-/gi, "_");
        const schemaElem = {
          class: "input-text",
          placeholder: elem.field_name,
          type: elem.field_type,
          name: name,
          label: elem.field_name,
          help: elem.field_hint,
        };
        additionalDataSchema.push(schemaElem);
      }
    }
  }
  return additionalDataSchema;
};

export const computeStandardAdminDataWithAdditionalFormsSchema = (
  countriesList: any,
  additionalForms: any,
  validationPhone = "required",
  validationEmail = "email"
) => {
  let adminData = computeStandardAdminDataSchema(
    validationPhone,
    validationEmail,
    false,
    countriesList
  );

  if (additionalForms?.length) {
    const children = computeAdditionalDataSchema(additionalForms);

    let additionalSchema: any = null;

    if (children.length != 0) {
      additionalSchema = [
        {
          component: "h4",
          class: "form-title",
          name: "new-section",
          children: i18n.t("immunization.additional_informations"),
        },
        {
          component: "div",
          class: "container-row-input",
          name: "row14",
          children: children,
        },
      ];
    }
    adminData = adminData.concat(additionalSchema);
  }

  return adminData;
};

export const computeAdminDataSchema = (
  availabilities: any,
  values: any,
  countriesList: any,
  showContraindications: boolean = false,
  campaignsList: any = null,
  consentSentenceData: string = "",
  privacyNoticeLinkData: string = "",
  personalizeData: any = null,
  additionalForms: any = null,
  appointmentType: string = cstSimple,
  campaignDates: any = null,
  localLanguage: string = "",
  timeZone: String = "Europe/Paris",
  sitesList: any = null
) => {
  const schema = new Array();
  let campaign = [
    {
      component: "div",
      class: "container-row-input",
      name: "row4",
      children: [
        {
          class: "input-select required",
          name: "campaign_id",
          id: "campaign_id",
          label: i18n.t("global.choose_a_campaign"),
          type: "select",
          validation: "required",
          placeholder: i18n.t("global.choose_a_campaign"),
          validationName: i18n.t("global.choose_a_campaign"),
          options: getCampaign(campaignsList),
        },
      ],
    },
  ];

  if (sitesList) {
    const fSitesList = sitesList.map((site: any) => {
      return { value: site.id, label: site.site_name };
    });
    const site: any = {
      class: "input-select required",
      name: "site_id",
      id: "site_id",
      label: i18n.t("global.site"),
      type: "select",
      validation: "required",
      options: fSitesList,
      validationName: i18n.t("global.site"),
    };
    campaign[0].children.unshift(site);
  }

  const adminData = computeStandardAdminDataWithAdditionalFormsSchema(
    countriesList,
    additionalForms,
    personalizeData?.employeeIdField,
    "required"
  );

  const contraindications = getContraindications(
    showContraindications,
    campaignsList,
    values
  );

  let timeSlot = [
    {
      component: "div",
      class: "container-row-calendar",
      name: "row7",
      children: [
        {
          class: "input-calendar required",
          name: "time",
          label: i18n.t("global.select_time"),
          type: "calendar",
          validation: "required",
          availabilities: availabilities,
          timeZone: timeZone,
        },
      ],
    },
  ];

  if (appointmentType == cstRecurring) {
    const optionsDate: any = {
      year: "numeric",
      month: "long",
      weekday: "long",
      day: "2-digit",
    };

    const obj: any = {
      class: "mt-5 col col-12",
      component: "h5",
      children:
        i18n.t("immunization.recurring_appointment") +
        new Date(campaignDates[0]).toLocaleString(localLanguage, optionsDate) +
        " " +
        i18n.t("global.and") +
        " " +
        new Date(campaignDates[1]).toLocaleString(localLanguage, optionsDate),
    };
    timeSlot[0].children.splice(0, 0, obj);
  }

  const consentSentence = consentSentenceData
    ? consentSentenceData
    : i18n.t("global.consent");

  const privacyNoticeLink = privacyNoticeLinkData
    ? privacyNoticeLinkData
    : "/doc/privacy_notice.pdf";

  const consentAndSubmit = [
    {
      component: "div",
      class: "container-row-condition",
      name: "row8",
      children: [
        {
          type: "checkbox",
          class: "input-checkbox required",
          name: "consent",
          validation: "required",
        },
        {
          component: "a",
          class: "form-link-condition",
          target: "_BLANK",
          href: "/doc/web_app_terms_conditions.pdf",
          children: consentSentence,
        },
      ],
    },
    {
      component: "div",
      class: "container-row-condition",
      children: [
        {
          component: "a",
          class: "form-link-condition-second",
          target: "_BLANK",
          name: "row8a2",
          href: privacyNoticeLink,
          children: i18n.t("global.please_read_privacy_notice"),
        },
      ],
    },
    {
      component: "div",
      class: "container-row-submit",
      name: "row-submit",
      children: [
        {
          class: "input-submit",
          type: "submit",
          label: i18n.t("global.save"),
        },
      ],
    },
  ];

  // const submit: any = [
  //   {
  //     component: "div",
  //     class: "container-row-condition",
  //     name: "row-submit",
  //     children: [
  //       {
  //         class: "pa-2",
  //         type: "submit",
  //         label: i18n.t("global.save"),
  //       },
  //     ],
  //   },
  // ];
  if (!availabilities) {
    timeSlot = new Array();
  }

  if (!campaignsList) {
    campaign = new Array();
  }

  return schema.concat(
    campaign,
    adminData,
    contraindications,
    timeSlot,
    consentAndSubmit
  );
};

export const computeAdminAdditionalDataSchema = (
  sitesList: any,
  countriesList: any,
  patientType: any,
  displayInsuranceProgram: boolean = false,
  customersList: any = [],
  displaySignature: boolean = false
) => {
  const countries: any = {};
  for (const c of getCountriesList(countriesList, true)) {
    countries[c.value] = c.text;
  }

  const OtherTitle: any = [
    {
      component: "h4",
      class: "form-title",
      name: "new-section-contact",
      children: i18n.t("global.other"),
    },
  ];

  const statusType: any = [
    {
      component: "div",
      class: "container-row-input",
      name: "row-status-type",
      children: [
        {
          class: "input-select required",
          name: "patient_type",
          label: i18n.t("global.status"),
          type: "select",
          validation: "required",
          options: patientType,
          validationName: i18n.t("global.type"),
        },
      ],
    },
  ];

  let customersListSection: any = {
    component: "div",
    class: "d-flex  justify-space-left",
    name: "empty-customer",
  };

  if (customersList?.length > 0) {
    customersListSection = {
      class: "input-select required",
      name: "customer",
      id: "customer",
      validation: "required",
      label: i18n.t("global.client"),
      type: "select",
      options: customersList,
      validationName: i18n.t("global.client"),
    };
  }

  const site: any = [
    {
      component: "div",
      class: "container-row-input",
      name: "row-sites",
      children: [
        {
          class: "input-select required",
          name: "site_id",
          id: "site_id",
          label: i18n.t("global.site"),
          type: "select",
          validation: "required",
          options: sitesList,
          validationName: i18n.t("global.site"),
        },
        customersListSection,
      ],
    },
  ];

  let insurance_program: any = [];

  if (displayInsuranceProgram) {
    insurance_program = [
      {
        component: "div",
        class: "container-row-input",
        name: "row-insurance_program",
        children: [
          {
            class: "input-text",
            type: "text",
            name: "insurance_number",
            label: i18n.t("outpatient.insurance_number"),

            validationName: i18n.t("outpatient.insurance_number"),
          },
          {
            class: "input-text",
            type: "text",
            name: "program_number",
            label: i18n.t("outpatient.program_number"),

            validationName: i18n.t("outpatient.program_number"),
          },
        ],
      },
    ];
  }

  let signature: any = [];

  if (displaySignature) {
    signature = [
      {
        component: "div",
        class: "container-row-signature",
        name: "row-signature",
        children: [
          {
            class: "signature required",
            type: "signature",
            name: "signature",
            validation: "required",
            label: i18n.t("global.signature"),
            validationName: i18n.t("global.signature"),
          },
        ],
      },
    ];
  }

  return [].concat(OtherTitle, site, statusType, insurance_program, signature);
};
