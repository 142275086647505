


















































































































































import Vue from "vue";
import * as _ from "lodash";
import DBHelper from "@/tscript/dbHelper";
import db from "@/firebase/db";
import fb from "firebase/app";
import { mapState, mapActions } from "vuex";
import Header from "@/components/global/Header.vue";
import { checkI18 } from "@/tscript/utils";
import { getColor } from "@/tscript/utils/index";
import { sendMailInvitation } from "@/components/helper/mail";
import i18n from "@/i18n";
import NewPatientForOutpatientDialog from "@/components/global/NewPatientForModuleDialog/NewPatientForModuleDialog.vue";
import MycDialog from "@/components/global/dialog/MycDialog.vue";

export default Vue.extend({
  name: "HomeContactBook",
  components: {
    Header,
    NewPatientForOutpatientDialog,
    MycDialog,
  },
  props: ["isMobile"],
  data() {
    return {
      getColor: getColor,
      openAdmissionDialog: false,
      contactsList: new Array(),
      dbHelper: new DBHelper(db),
      search: "",
      loading: false,
      patientId: "",
      dialog: false,
      patientsList: new Array(),
      usersListNetwork: new Array(),
      dialogShareMedicalCase: false,
      step: 1,
      patientToShare: {} as any,
      userIdToSend: "",
      questionToShare: "",
    };
  },

  methods: {
    sendMailInvitation,
    ...mapActions("analytics", ["logAction"]),
    ...mapActions("snackbar", ["setSnackbarAction"]),
    checkI18,
    getSearchName(patientId: any) {
      const localname = _.cloneDeep(this.$store.state.patientsList).find(
        (patient: any) => patient.id == patientId
      );
      return (
        localname?.name +
        " " +
        localname?.last_name +
        " " +
        localname?.speciality +
        " " +
        localname?.location
      );
    },
    closeShareDialog() {
      this.dialogShareMedicalCase = false;
      this.step = 1;
    },
    async sharePatient(row: any) {
      this.dialogShareMedicalCase = true;
      this.patientToShare = row;
    },
    async sendMessage(message: string) {
      const constraints: any = {
        where: [
          {
            field: "participants",
            compare: "array-contains",
            value: this.userData.id,
          },
        ],
      };
      let discussionResult: any =
        await this.dbHelper.getAllDataFromCollectionWithAll(
          "discussions",
          constraints
        );
      const FieldValue = fb.firestore.FieldValue;
      if (discussionResult?.length) {
        discussionResult = _.find(discussionResult, (discuss) =>
          discuss.participants.includes(this.userIdToSend)
        );
        if (discussionResult) {
          await this.dbHelper.updateDataToCollection(
            "discussions",
            discussionResult.id,
            {
              messages: FieldValue.arrayUnion({
                user_id: this.userData.id,
                content: message,
              }),
            }
          );
        } else {
          const res = await this.dbHelper.addDataToCollection("discussions", {
            messages: FieldValue.arrayUnion({
              user_id: this.userData.id,
              content: message,
            }),
            participants: [this.userData.id, this.userIdToSend],
          });
        }
      } else {
        await this.dbHelper.addDataToCollection("discussions", {
          messages: FieldValue.arrayUnion({
            user_id: this.userData.id,
            content: message,
          }),
          participants: [this.userData.id, this.userIdToSend],
        });
      }
    },
    async confirmSharePatient() {
      this.addPatientToAccessList();
      const automaticMessage =
        this.$i18n.t("global.patient_has_been_shared") +
        " " +
        this.userData.last_name +
        " " +
        this.userData.first_name +
        ". " +
        this.$i18n.t("global.find_it_in") +
        " " +
        this.patientToShare.name +
        " " +
        this.$i18n.t("global.in_your");
      await this.sendMessage(automaticMessage);
      await this.sendMessage(this.questionToShare);
      this.closeShareDialog();
    },
    async addPatientToAccessList() {
      const userToSend: any = await this.dbHelper.getDocFromCollection(
        "users",
        this.userIdToSend
      );
      if (!userToSend.access_list) {
        userToSend.access_list = [];
      }
      userToSend.access_list.push(this.patientToShare.id);
      await this.dbHelper.setDataToCollection(
        "users",
        userToSend.id,
        userToSend
      );
      this.step = 1;
      this.patientToShare = {};
    },
    getDateWellFormatted(item: any) {
      if (item.created_at) {
        return new Date(item.created_at).toLocaleString();
      } else {
        return new Date(item.created_at).toLocaleDateString().substring(0, 10);
      }
    },
    goTo(row: any) {},

    getDoctorName(doctorId: string) {
      let doctorFullName = "";
      if (doctorId == this.userData?.id) {
        doctorFullName =
          this.userData.last_name + " " + this.userData.first_name;
      } else {
        const usersList = this.$store.state.usersList;
        const doctor = _.find(usersList, (user: any) => user.id == doctorId);
        doctorFullName = doctor ? doctor.name : "";
      }
      return doctorFullName;
    },

    getCountry(countryCode: string) {
      const countryPatient = _.find(
        this.countriesList,
        (country: any) => country.value == countryCode
      );
      const country = countryPatient ? countryPatient?.label : "";
      return country;
    },

    async loadData() {
      if (this.userData) {
        this.loading = true;
        this.patientsList = this.$store.state.patientsList ?? new Array();
        this.patientsList = this.patientsList.filter(
          (patient: any) => patient.done_by == this.userData.id
        );
        if (this.userData?.access_list) {
          for (const patient of this.userData.access_list) {
            const patientAccess: any = await this.dbHelper.getDocFromCollection(
              "patients_admin",
              patient
            );
            if (patientAccess) {
              this.patientsList.push(patientAccess);
            }
          }
        }
        this.patientsList.map((patient) => {
          patient["doctor_name"] = this.getDoctorName(patient?.done_by);
          patient["name"] = patient.last_name + " " + patient.first_name;
          patient["location"] =
            patient?.city + ", " + this.getCountry(patient?.country);
          return patient;
        });
        this.loading = false;
      }
    },

    goToPatientFolder(row: any) {
      this.$router
        .push({ name: "patient", params: { id: row.id } })
        .catch(() => {});
    },
    goToPatientAdmin(row: any) {
      this.$router
        .push({ name: "account", params: { id: row.id } })
        .catch(() => {});
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("countries", ["countriesList"]),
    headers() {
      let computedHeaders = new Array();
      if (!this.isMobile) {
        computedHeaders = [
          {
            text: i18n.t("global.name"),
            value: "name",
            align: "center",
          },
          {
            text: i18n.t("global.location"),
            value: "location",
            align: "center",
          },
          {
            text: i18n.t("global.birthday_date"),
            value: "birth_date",
            align: "center",
          },
          {
            text: i18n.t("global.shared_by"),
            value: "doctor_name",
            align: "center",
          },
          {
            text: i18n.t("global.last_update"),
            value: "last_update",
            sort: (a: any, b: any) => {
              const x =
                new Date(a._seconds * 1000).toISOString().substr(0, 10) >
                new Date(b._seconds * 1000).toISOString().substr(0, 10)
                  ? -1
                  : 1;
              return x;
            },
            align: "center",
          },
          {
            text: i18n.t("global.actions"),
            value: "actions",
            sortable: false,
            align: "center",
            width: "13%",
          },
        ];
      } else {
        computedHeaders = [
          {
            text: i18n.t("global.name"),
            value: "name",
            align: "center",
          },
          {
            text: i18n.t("global.location"),
            value: "location",
            align: "center",
          },
          {
            text: i18n.t("global.actions"),
            value: "actions",
            sortable: false,
            align: "center",
            width: "13%",
          },
        ];
      }
      return computedHeaders;
    },
  },
  created() {
    this.logAction({ event_name: "home-patients" });
    this.usersListNetwork = this.$store.state.usersList.filter((user: any) =>
      this.userData.network.current.includes(user.id)
    );
    this.usersListNetwork.map((item) => {
      item.name = item.last_name + " " + item.first_name;
      return item;
    });
    this.loadData();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setPatientsList" && state?.patientsList) {
        this.loadData();
      }
    });
  },
});
